import { atom } from "recoil";
import { FeedbackScoreAssessment } from "../../domain/feedback/feedbackScoreAssessment";
import { FeedbackUnit } from "../../domain/feedback/feedbackUnit";

export const feedback문법State = atom<FeedbackScoreAssessment>({
  key: "Feedback문법",
  default: new FeedbackScoreAssessment("문법\n(올바른 문법을 사용하는지)", 0),
});

export const feedback국문이해도State = atom<FeedbackScoreAssessment>({
  key: "Feedback국문이해도",
  default: new FeedbackScoreAssessment("국문 이해도\n(오역이 없는지)", 0),
});

export const feedback작문능력State = atom<FeedbackScoreAssessment>({
  key: "Feedback작문능력",
  default: new FeedbackScoreAssessment(
    "작문 능력\n(유저 입장에서 쉽게 이해되는지\n/ readability)",
    0
  ),
});

export const feedback게임이해도State = atom<FeedbackScoreAssessment>({
  key: "Feedback게임이해도",
  default: new FeedbackScoreAssessment(
    "게임 이해도\n(적절한 용어, 동사 시제\n등을 사용하는지)",
    0
  ),
});

export const feedback정확도State = atom<FeedbackScoreAssessment>({
  key: "Feedback정확도",
  default: new FeedbackScoreAssessment(
    "정확도\n(오탈자, 누락, 태그 오류\n같은 실수가 없는지",
    0
  ),
});

export const feedbackOverallCommentState = atom<string>({
  key: "FeedbackOverallComment",
  default: "",
});

export const feedbackUnitsState = atom<FeedbackUnit[]>({
  key: "FeedbackUnits",
  default: [],
});

export const feedbackIdState = atom<string | null>({
  key: "FeedbackId",
  default: null,
});

export const feedbackTestPaperTokenState = atom<string | null>({
  key: "FeedbackTestPaperTokenState",
  default: null,
});
