import { useEffect } from "react";
import { Feedback } from "../../domain/feedback/feedback";
import { useGetFeedbackPropertyStates } from "./useGetFeedbackPropertyStates";
import { saveFeedbackService } from "../../infrastructure/feedback/saveFeedbackService";

export const useAutoSaveFeedback = () => {
  const DEFAULT_AUTO_SAVE_SEC = "300";

  const [
    feedbackUnits,
    feedbackOverallComment,
    feedbackId,
    feedbackTestPaperToken,
  ] = useGetFeedbackPropertyStates();

  useEffect(() => {
    if (feedbackId === null || feedbackTestPaperToken === null) return;

    const interval = setInterval(() => {
      saveFeedbackService(
        new Feedback(
          feedbackId,
          feedbackTestPaperToken,
          feedbackUnits,
          feedbackOverallComment
        )
      ).then();
    }, parseInt(process.env.REACT_APP_FEEDBACK_SAVE_INTERVAL_SEC ?? DEFAULT_AUTO_SAVE_SEC) * 1000);
    return () => clearInterval(interval);
  }, [
    feedbackUnits,
    feedbackOverallComment,
    feedbackId,
    feedbackTestPaperToken,
  ]);
};
