import React from "react";
import { ReadyNameComponent } from "./readyNameComponent";
import { ReadyLanguageComponent } from "./readyLanguageComponent";
import { ReadyEmailComponent } from "./readyEmailComponent";
import { ReadyContactComponent } from "./readyContactComponent";
import { ReadyStartComponent } from "./readyStartComponent";
import { ReadyRestoreComponent } from "./readyRestoreComponent";

export function ReadyPage(): JSX.Element {
  return (
    <div>
      <h1>테스트 페이퍼</h1>

      <ReadyNameComponent />
      <ReadyLanguageComponent />
      <ReadyEmailComponent />
      <ReadyContactComponent />
      <ReadyStartComponent />
      <ReadyRestoreComponent />
    </div>
  );
}
