import React from "react";
import { FeedbackQuestionComponent } from "./feedbackQuestionComponent";
import { useCurrentPng } from "recharts-to-png";
import { FeedbackScoreComponent } from "./score/feedbackScoreComponent";
import { FeedbackOverallCommentComponent } from "./feedbackOverallCommentComponent";
import { FeedbackSubmitComponent } from "./feedbackSubmitComponent";
import { useAutoSaveFeedback } from "../../application/feedback/useAutoSaveFeedback";
import { useGetFeedback } from "../../application/feedback/useGetFeedback";

export const FeedbackPage = (): JSX.Element => {
  useGetFeedback();
  useAutoSaveFeedback();
  const [png, { ref: areaRef }] = useCurrentPng();

  return (
    <div>
      <FeedbackQuestionComponent />
      <FeedbackOverallCommentComponent />
      <FeedbackScoreComponent areaRef={areaRef} />
      <FeedbackSubmitComponent png={png} />
    </div>
  );
};
