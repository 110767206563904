import { fetchPostWithoutResponse } from "../common/api";
import { TestPaperQuestionUnit } from "../../domain/testPaperQuestionUnit";

export async function submitTestPaperAnswerService(
  testPaperToken: string,
  testPaperQuestionUnits: TestPaperQuestionUnit[]
): Promise<void> {
  await fetchPostWithoutResponse("test-paper/submit", {
    testPaperToken: testPaperToken,
    testPaperQuestion: testPaperQuestionUnits,
  });
}
