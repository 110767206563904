import React from "react";
import { useSetRecoilState } from "recoil";
import { applicantContactState } from "./readyStates";

export function ReadyContactComponent() {
  const setContact = useSetRecoilState(applicantContactState);

  return (
    <div>
      <p>연락처</p>
      <input
        onChange={(e) => setContact(e.target.value)}
        placeholder={"본인의 연락처를 적어주세요."}
        style={{ width: 175 }}
      />
    </div>
  );
}
