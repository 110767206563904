import { useSetRecoilState } from "recoil";
import { applicantNameState } from "./readyStates";

export function ReadyNameComponent(): JSX.Element {
  const setName = useSetRecoilState(applicantNameState);

  return (
    <div>
      <p>성명</p>
      <input
        onChange={(e) => setName(e.target.value)}
        placeholder={"본인의 이름을 적어주세요."}
        style={{ width: 160 }}
      />
    </div>
  );
}
