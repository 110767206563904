import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getAutoSavedTestPaperAnswerService } from "../../infrastructure/admin/getAutoSavedTestPaperAnswerService";
import { getLastAutoSavedTestPaperAnswerService } from "../../infrastructure/admin/getLastAutoSavedTestPaperAnswerService";
import { testPaperQuestionUnitsState } from "../../presentation/test/testStates";
import { testPaperTokenState } from "../../presentation/ready/readyStates";

export const useLoadAutoSave = () => {
  const navigate = useNavigate();
  const setTestPaperToken = useSetRecoilState(testPaperTokenState);
  const setTestPaperQuestionUnits = useSetRecoilState(
    testPaperQuestionUnitsState
  );

  return async (testPaperToken: string, autoSaveToken: string) => {
    const testPaperAutoSave =
      autoSaveToken === null || autoSaveToken.trim() === ""
        ? await getLastAutoSavedTestPaperAnswerService(testPaperToken)
        : await getAutoSavedTestPaperAnswerService(
            testPaperToken,
            autoSaveToken
          );

    if (testPaperAutoSave === null)
      throw Error("테스트 페이퍼 데이터가 없습니다.");
    else {
      setTestPaperToken(testPaperToken);
      setTestPaperQuestionUnits(testPaperAutoSave.testPaperQuestion);
    }

    navigate("/test");
  };
};
