import { useRecoilValue } from "recoil";
import {
  applicantContactState,
  applicantEmailState,
  applicantNameState,
  sourceLanguageState,
  targetLanguageState,
} from "../../presentation/ready/readyStates";
import { 테스트페이퍼_언어 } from "../../domain/테스트페이퍼_언어";

export const useGetApplicantInformationPropertyStates: () => [
  string,
  테스트페이퍼_언어 | null,
  테스트페이퍼_언어 | null,
  string,
  string
] = () => {
  const name = useRecoilValue(applicantNameState);
  const source = useRecoilValue(sourceLanguageState);
  const target = useRecoilValue(targetLanguageState);
  const email = useRecoilValue(applicantEmailState);
  const contact = useRecoilValue(applicantContactState);

  return [name, source, target, email, contact];
};
