import { 테스트페이퍼_언어 } from "../../domain/테스트페이퍼_언어";
import { useEffect, useState } from "react";
import { useRecoilValueLoadable } from "recoil";
import { testPaperLanguagesState } from "../../presentation/ready/readyStates";

export const useGetTestPaperLanguages = (): 테스트페이퍼_언어[] => {
  const [languages, setLanguages] = useState<테스트페이퍼_언어[]>([]);
  const testPaperLanguages = useRecoilValueLoadable(testPaperLanguagesState);

  useEffect(() => {
    try {
      if (testPaperLanguages.state === "loading") return;
      setLanguages(testPaperLanguages.valueOrThrow());
    } catch {
      alert("서버에서 데이터를 불러오지 못했습니다.");
    }
  }, [testPaperLanguages]);

  return languages;
};
