import React from "react";
import { TestQuestionComponent } from "./testQuestionComponent";
import { TestSubmitComponent } from "./testSubmitComponent";
import { useAutoSaveTestPaper } from "../../application/test/useAutoSaveTestPaper";

export function TestPage(): JSX.Element {
  useAutoSaveTestPaper();

  return (
    <div>
      <h1>BOBGATE TestPaper</h1>
      <TestQuestionComponent />
      <TestSubmitComponent />
    </div>
  );
}
