import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ReadyPage } from "./presentation/ready/readyPage";
import { NotFoundPage } from "./presentation/not-found/notFoundPage";
import { TestPage } from "./presentation/test/testPage";
import { RecoilRoot } from "recoil";
import { RestorePage } from "./presentation/restore/restorePage";
import { AdminPage } from "./presentation/admin/adminPage";
import { FeedbackPage } from "./presentation/feedback/feedbackPage";
import { CautionPage } from "./presentation/caution/cautionPage";

function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <div className="App">
          <Routes>
            <Route path="/" element={<ReadyPage />}></Route>
            <Route path="/caution" element={<CautionPage />}></Route>
            <Route path="/test" element={<TestPage />}></Route>
            <Route path="/restore" element={<RestorePage />}></Route>
            <Route path="/admin" element={<AdminPage />}></Route>
            <Route
              path="/feedback/:testPaperToken/:feedbackToken"
              element={<FeedbackPage />}
            />
            <Route path="*" element={<NotFoundPage />}></Route>
          </Routes>
        </div>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
