import { ApplicantInformation } from "../../domain/applicantInformation";
import { fetchPostWithResponse } from "../common/api";

export async function startTestPaperService(
  applicantInformation: ApplicantInformation
): Promise<string> {
  return (
    await fetchPostWithResponse<ApplicantInformation, number>(
      "test-paper/start",
      applicantInformation
    )
  ).toString();
}
