type EnumDictionary<T extends string, U> = {
  [K in T]: U;
};

export enum 테스트페이퍼_언어 {
  KO = "KO",
  EN = "EN",
  FR = "FR",
  DE = "DE",
  ES = "ES",
  PT = "PT",
  CS = "CS",
  CT = "CT",
  JA = "JA",
  VI = "VI",
  TH = "TH",
  RU = "RU",
  ID = "ID",
  AR = "AR",
  TR = "TR",
  NL = "NL",
  PL = "PL",
  IT = "IT",
}

export const 테스트페이퍼_언어_Dictionary: EnumDictionary<
  테스트페이퍼_언어,
  string
> = {
  [테스트페이퍼_언어.KO]: "한국어 : KO",
  [테스트페이퍼_언어.EN]: "영어 : EN",
  [테스트페이퍼_언어.FR]: "프랑스어 : FR",
  [테스트페이퍼_언어.DE]: "독일어 : DE",
  [테스트페이퍼_언어.ES]: "스페인어 : ES(Latin)",
  [테스트페이퍼_언어.PT]: "포르투갈어 : PT(Brasil)",
  [테스트페이퍼_언어.CS]: "중국어 간체 : CS",
  [테스트페이퍼_언어.CT]: "대만어 : TW",
  [테스트페이퍼_언어.JA]: "일본어 : JA",
  [테스트페이퍼_언어.VI]: "베트남어 : VI",
  [테스트페이퍼_언어.TH]: "태국어 : TH",
  [테스트페이퍼_언어.RU]: "러시아어 : RU",
  [테스트페이퍼_언어.ID]: "인도네시아어 : ID",
  [테스트페이퍼_언어.AR]: "아랍어 : AR",
  [테스트페이퍼_언어.TR]: "터키어 : TR",
  [테스트페이퍼_언어.NL]: "네덜란드어 : NL",
  [테스트페이퍼_언어.PL]: "폴란드어 : PL",
  [테스트페이퍼_언어.IT]: "이탈리아어 : IT",
};
