import React from "react";
import { useSubmitFeedback } from "../../application/feedback/useSubmitFeedback";
import { ApiCallError } from "../../application/error/apiCallError";

export const FeedbackSubmitComponent = ({
  png,
}: {
  png: () => Promise<string | undefined>;
}) => {
  const submitFeedback = useSubmitFeedback();

  return (
    <div>
      <button
        onClick={async (event) => {
          const target = event.currentTarget;
          target.disabled = true;

          try {
            await submitFeedback(png);
            alert("제출이 완료되었습니다.");
          } catch (e: any) {
            if (e instanceof ApiCallError) alert("서버가 응답하지 않습니다.");
            if (e instanceof Error) alert(e.message);
            target.disabled = false;
          }
        }}
      >
        채점 완료
      </button>
    </div>
  );
};
