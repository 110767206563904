import React, { Dispatch, SetStateAction } from "react";
import {
  테스트페이퍼_언어,
  테스트페이퍼_언어_Dictionary,
} from "../../domain/테스트페이퍼_언어";

interface ReadyLanguageSingleProps {
  optionPlaceholder: string;
  languages: 테스트페이퍼_언어[];
  setLanguage: Dispatch<SetStateAction<테스트페이퍼_언어 | null>>;
}

export function ReadyLanguageSingleComponent({
  optionPlaceholder,
  languages,
  setLanguage,
}: ReadyLanguageSingleProps): JSX.Element {
  return (
    <select
      onChange={(e) => {
        setLanguage(e.target.value as unknown as 테스트페이퍼_언어);
      }}
      defaultValue={"none"}
    >
      <option value="none" disabled>
        {optionPlaceholder}
      </option>
      {languages.map((language) => {
        return (
          <option key={language} value={language}>
            {테스트페이퍼_언어_Dictionary[language]}
          </option>
        );
      })}
    </select>
  );
}
