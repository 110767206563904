import { useSetRecoilState } from "recoil";
import {
  feedbackIdState,
  feedbackOverallCommentState,
  feedbackTestPaperTokenState,
  feedbackUnitsState,
} from "../../presentation/feedback/feedbackStates";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FeedbackToken } from "../../domain/feedback/feedbackToken";
import { getFeedbackService } from "../../infrastructure/feedback/getFeedbackService";

export const useGetFeedback = () => {
  const { feedbackToken } = useParams();
  const setFeedbackOverallComment = useSetRecoilState(
    feedbackOverallCommentState
  );
  const setFeedbackId = useSetRecoilState(feedbackIdState);
  const setFeedbackUnits = useSetRecoilState(feedbackUnitsState);
  const setFeedbackTestPaperToken = useSetRecoilState(
    feedbackTestPaperTokenState
  );

  useEffect(() => {
    if (feedbackToken === undefined) return;

    getFeedbackService(new FeedbackToken(feedbackToken)).then((value) => {
      setFeedbackOverallComment(value.총평);
      setFeedbackId(value.id);
      setFeedbackUnits(value.testPaperFeedbackUnits);
      setFeedbackTestPaperToken(value.testPaperToken);
    });
  }, [
    feedbackToken,
    setFeedbackId,
    setFeedbackOverallComment,
    setFeedbackTestPaperToken,
    setFeedbackUnits,
  ]);
};
