import React from "react";
import { useSetRecoilState } from "recoil";
import { applicantEmailState } from "./readyStates";

export function ReadyEmailComponent() {
  const setEmail = useSetRecoilState(applicantEmailState);

  return (
    <div>
      <p>E-Mail 주소</p>
      <input
        onChange={(e) => setEmail(e.target.value)}
        placeholder={"본인의 메일 주소를 적어주세요."}
        style={{ width: 190 }}
      />
    </div>
  );
}
