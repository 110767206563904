import React from "react";
import { TestPaperQuestionUnit } from "../../domain/testPaperQuestionUnit";
import styles from "./testPage.module.css";

interface TestQuestionRowProps {
  testPaperQuestionUnit: TestPaperQuestionUnit;
  index: number;
  updateTestPaperQuestionUnit: (
    testPaperQuestionUnit: TestPaperQuestionUnit,
    targetIndex: number
  ) => void;
}

export function TestQuestionRowComponent({
  testPaperQuestionUnit,
  index,
  updateTestPaperQuestionUnit,
}: TestQuestionRowProps) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 4fr 4fr",
      }}
      className={styles.borderDiv}
    >
      <div key={testPaperQuestionUnit.key + "Key"}>
        {testPaperQuestionUnit.key}
      </div>
      <div
        key={testPaperQuestionUnit.key + "Question"}
        style={{
          whiteSpace: "pre-wrap",
          textAlign: "justify",
          padding: 5,
        }}
      >
        {testPaperQuestionUnit.question}
      </div>
      <textarea
        key={testPaperQuestionUnit.key + "Answer"}
        onChange={(event) => {
          updateTestPaperQuestionUnit(
            new TestPaperQuestionUnit(
              testPaperQuestionUnit.key,
              testPaperQuestionUnit.question,
              event.target.value
            ),
            index
          );
        }}
        value={testPaperQuestionUnit.answer}
      ></textarea>
    </div>
  );
}
