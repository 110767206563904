import { useState } from "react";
import { useLoadAdminData } from "../../application/admin/useLoadAdminData";
import { ApiCallError } from "../../application/error/apiCallError";

export function AdminPage() {
  const [testPaperToken, setTestPaperToken] = useState("");
  const [autoSaveToken, setAutoSaveToken] = useState("");
  const [adminData, loadAdminData] = useLoadAdminData();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <input
        placeholder="세션키"
        onChange={(e) => setTestPaperToken(e.target.value)}
      />
      <input
        placeholder="자동저장키 (옵션)"
        onChange={(e) => setAutoSaveToken(e.target.value)}
      />
      <button
        onClick={async (event) => {
          const target = event.currentTarget;

          if (testPaperToken === null || testPaperToken.trim() === "") return;

          target.disabled = true;

          try {
            await loadAdminData(testPaperToken, autoSaveToken);
          } catch (e: any) {
            if (e instanceof ApiCallError) alert("서버가 응답하지 않습니다.");
            else if (e instanceof Error) alert(e.message);
          }

          target.disabled = false;
        }}
      >
        불러오기
      </button>
      <pre>{JSON.stringify(adminData, null, 2)}</pre>
    </div>
  );
}
