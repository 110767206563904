import React from "react";
import { TestQuestionRowComponent } from "./testQuestionRowComponent";
import { testPaperQuestionUnitsState } from "./testStates";
import { TestPaperQuestionUnit } from "../../domain/testPaperQuestionUnit";
import { useRecoilState } from "recoil";
import styles from "./testPage.module.css";

export function TestQuestionComponent(): JSX.Element {
  const [testPaperQuestionUnits, setTestPaperQuestionUnits] = useRecoilState(
    testPaperQuestionUnitsState
  );

  const updateTestPaperQuestionUnit = (
    testPaperQuestionUnit: TestPaperQuestionUnit,
    targetIndex: number
  ) => {
    setTestPaperQuestionUnits(
      testPaperQuestionUnits.map((value, index) =>
        index === targetIndex ? testPaperQuestionUnit : value
      )
    );
  };

  return (
    <div style={{ margin: "10" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 4fr 4fr",
        }}
        className={styles.borderDiv}
      >
        <div>Key</div>
        <div>SOURCE</div>
        <div>TARGET</div>
      </div>
      {testPaperQuestionUnits
        .map((testPaperQuestionUnit, index) => (
          <TestQuestionRowComponent
            key={index}
            testPaperQuestionUnit={testPaperQuestionUnit}
            index={index}
            updateTestPaperQuestionUnit={updateTestPaperQuestionUnit}
          />
        ))
        .slice(1)}
    </div>
  );
}
