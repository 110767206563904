import { FeedbackScoreAssessment } from "../../../domain/feedback/feedbackScoreAssessment";
import { useEffect, useState } from "react";
import { RecoilState, useRecoilState } from "recoil";
import {
  feedback게임이해도State,
  feedback국문이해도State,
  feedback문법State,
  feedback작문능력State,
  feedback정확도State,
} from "../feedbackStates";
import { useGetFeedbackScoreAssessments } from "../../../application/feedback/useGetFeedbackScoreAssessments";

export const FeedbackScoreFormComponent = () => {
  let [totalScore, setTotalScore] = useState(0);
  const [문법, 국문이해도, 작문능력, 게임이해도, 정확도] =
    useGetFeedbackScoreAssessments();

  useEffect(() => {
    const sum =
      (정확도.realScore +
        문법.realScore +
        국문이해도.realScore +
        게임이해도.realScore +
        작문능력.realScore) /
      5;
    setTotalScore(sum);
  }, [문법, 국문이해도, 작문능력, 게임이해도, 정확도]);

  return (
    <table
      style={{
        border: "1px solid",
        borderCollapse: "collapse",
        marginLeft: "12px",
      }}
    >
      <tbody>
        <tr>
          <td
            style={{
              margin: 0,
              textAlign: "left",
              background: "yellow",
              border: "1px solid",
            }}
          >
            채점 항목
          </td>
          <td
            style={{
              margin: 0,
              textAlign: "left",
              background: "yellow",
              border: "1px solid",
            }}
          >
            점수
          </td>
        </tr>
      </tbody>
      <ScoreRow
        title={"문법"}
        description={"(올바른 문법 사용하는지)"}
        assessment={feedback문법State}
      />
      <ScoreRow
        title={"국문 이해도"}
        description={"(오역이 없는지)"}
        assessment={feedback국문이해도State}
      />
      <ScoreRow
        title={"작문 능력"}
        description={"(유저 입장에서 쉽게 이해되는지 / readability)"}
        assessment={feedback작문능력State}
      />
      <ScoreRow
        title={"게임 이해도"}
        description={"(적절한 용어, 동사 시제 등을 사용하는지)"}
        assessment={feedback게임이해도State}
      />
      <ScoreRow
        title={"정확도"}
        description={"(오탈자, 누락, 태그 오류 같은 실수가 없는지)"}
        assessment={feedback정확도State}
      />
      <tbody>
        <tr>
          <td
            style={{
              border: "1px solid",
            }}
          >
            <p
              style={{
                margin: 0,
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              총점
            </p>
          </td>
          <td
            style={{
              border: "1px solid",
              width: "25%",
              height: "1em",
              padding: "0px",
            }}
          >
            {totalScore}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

interface ScoreRowProps {
  title: string;
  description: string;
  assessment: RecoilState<FeedbackScoreAssessment>;
}

const ScoreRow = ({ title, description, assessment }: ScoreRowProps) => {
  const [value, setter] = useRecoilState(assessment);
  return (
    <tbody>
      <tr>
        <td
          style={{
            border: "1px solid",
          }}
        >
          <p
            style={{
              margin: 0,
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            {title}
          </p>
          <p style={{ margin: 0, textAlign: "left" }}>{description}</p>
        </td>
        <td
          style={{
            border: "1px solid",
            width: "25%",
            height: "1em",
            padding: "0px",
          }}
        >
          <input
            style={{
              width: "100%",
              height: "100%",
              margin: "0px",
              border: "0px",
              padding: "3px",
              outlineWidth: "0px",
              textAlign: "right",
              boxSizing: "border-box",
            }}
            dir="rtl"
            type="number"
            step={1}
            onChange={(e) => {
              let inputValue = parseFloat(e.target.value);
              if (inputValue > 5) inputValue = 5;
              if (inputValue < 1) inputValue = 1;
              e.target.value = inputValue.toString();
              setter(new FeedbackScoreAssessment(value.subject, inputValue));
            }}
          />
        </td>
      </tr>
    </tbody>
  );
};
