import React from "react";
import { useRecoilState } from "recoil";
import { feedbackOverallCommentState } from "./feedbackStates";
import { TextEditorComponent } from "./text-editor/textEditorComponent";

export const FeedbackOverallCommentComponent = () => {
  const [overallComment, setOverallComment] = useRecoilState(
    feedbackOverallCommentState
  );

  return (
    <div>
      <h2>총평</h2>
      <div
        style={{
          margin: 10,
          border: "black 1px solid",
        }}
      >
        <TextEditorComponent
          key="총평"
          pretext={overallComment.replaceAll("\n", "<br/>") ?? ""}
          setText={(value) =>
            setOverallComment(value.replaceAll("<br/>", "\n"))
          }
        ></TextEditorComponent>
      </div>
    </div>
  );
};
