import { submitFeedbackService } from "../../infrastructure/feedback/submitFeedbackService";
import { Feedback } from "../../domain/feedback/feedback";
import { FeedbackScore } from "../../domain/feedback/feedbackScore";
import { useGetFeedbackScoreAssessments } from "./useGetFeedbackScoreAssessments";
import { useGetFeedbackPropertyStates } from "./useGetFeedbackPropertyStates";

export const useSubmitFeedback: () => (
  png: () => Promise<string | undefined>
) => Promise<void> = () => {
  const [문법, 국문이해도, 작문능력, 게임이해도, 정확도] =
    useGetFeedbackScoreAssessments();
  const [
    feedbackUnits,
    feedbackOverallComment,
    feedbackId,
    feedbackTestPaperToken,
  ] = useGetFeedbackPropertyStates();

  return async (png: () => Promise<string | undefined>) => {
    if (feedbackId === null || feedbackTestPaperToken === null) {
      throw Error("제출 실패");
    }

    const 채점자이름 = prompt(
      "채점자 본인의 이름을 입력한 후 완료 메시지가 뜰 때까지 기다려 주세요."
    );

    if (채점자이름 === null || 채점자이름.trim() === "") {
      throw Error("제출 실패");
    }

    const image = await png();

    if (image === undefined) {
      throw Error("제출 실패");
    }

    await submitFeedbackService(
      new Feedback(
        feedbackId,
        feedbackTestPaperToken,
        feedbackUnits,
        feedbackOverallComment
      ),
      image,
      채점자이름,
      new FeedbackScore(
        문법.realScore,
        국문이해도.realScore,
        작문능력.realScore,
        게임이해도.realScore,
        정확도.realScore
      )
    );
  };
};
