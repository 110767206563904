import React from "react";
import { useNavigate } from "react-router-dom";

export function ReadyRestoreComponent() {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      onClick={() => navigate("/restore")}
      style={{ marginTop: 24, marginLeft: 24 }}
    >
      이어하기
    </button>
  );
}
