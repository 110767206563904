import { atom } from "recoil";
import { 테스트페이퍼_언어 } from "../../domain/테스트페이퍼_언어";
import { ApplicantInformation } from "../../domain/applicantInformation";
import { getTestPaperLanguagesService } from "../../infrastructure/question/getTestPaperLanguagesService";

export const testPaperLanguagesState = atom<테스트페이퍼_언어[]>({
  key: "TestPaperLanguages",
  default: getTestPaperLanguagesService(),
});

export const applicantNameState = atom<string>({
  key: "ApplicantName",
  default: "",
});

export const sourceLanguageState = atom<테스트페이퍼_언어 | null>({
  key: "SourceLanguage",
  default: null,
});

export const targetLanguageState = atom<테스트페이퍼_언어 | null>({
  key: "TargetLanguage",
  default: null,
});

export const applicantEmailState = atom<string>({
  key: "ApplicantEmail",
  default: "",
});

export const applicantContactState = atom<string>({
  key: "ApplicantContact",
  default: "",
});

export const applicantInformationState = atom<ApplicantInformation | null>({
  key: "ApplicantInformation",
  default: null,
});

export const testPaperTokenState = atom<string | null>({
  key: "TestPaperToken",
  default: null,
});
