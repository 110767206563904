import { FeedbackUnit } from "./feedbackUnit";

export class Feedback {
  constructor(
    readonly id: string,
    readonly testPaperToken: string,
    readonly testPaperFeedbackUnits: FeedbackUnit[],
    readonly 총평: string
  ) {}
}
