import { 테스트페이퍼_언어 } from "../../domain/테스트페이퍼_언어";
import { fetchGet } from "../common/api";
import { getEnumMembers } from "../../presentation/utility";

export async function getTestPaperLanguagesService(): Promise<
  테스트페이퍼_언어[]
> {
  const languages: 테스트페이퍼_언어[] = Array.from(
    new Set(await fetchGet("test-paper-question/language-list"))
  );
  languages.sort((a, b) => {
    const enumList = getEnumMembers(테스트페이퍼_언어);
    return enumList.indexOf(a) - enumList.indexOf(b);
  });
  return languages;
}
