import { useRecoilValue } from "recoil";
import { testPaperTokenState } from "../../presentation/ready/readyStates";
import { saveTestPaperAutoSaveService } from "../../infrastructure/test/saveTestPaperAutoSaveService";
import { testPaperQuestionUnitsState } from "../../presentation/test/testStates";
import { useCallback, useEffect } from "react";
import { ApiCallError } from "../error/apiCallError";

export const useAutoSaveTestPaper = () => {
  const DEFAULT_AUTO_SAVE_SEC = "300";
  const testPaperToken = useRecoilValue(testPaperTokenState);
  const testPaperQuestionUnits = useRecoilValue(testPaperQuestionUnitsState);

  const autoSaveTestPaper = useCallback(async () => {
    if (testPaperToken === null || testPaperToken.trim() === "") {
      alert("세션이 끊겼습니다. 세션 토큰을 다시 확인해주세요.");
      return;
    }

    try {
      await saveTestPaperAutoSaveService(
        testPaperToken,
        testPaperQuestionUnits
      );
    } catch (e: any) {
      if (e instanceof ApiCallError) alert("서버가 응답하지 않습니다.");
      else if (e instanceof Error) alert(e.message);
    }
  }, [testPaperQuestionUnits, testPaperToken]);

  useEffect(() => {
    const interval = setInterval(
      autoSaveTestPaper,
      parseInt(
        process.env.REACT_APP_AUTO_SAVE_INTERVAL_SEC ?? DEFAULT_AUTO_SAVE_SEC
      ) * 1000
    );
    return () => clearInterval(interval);
  }, [autoSaveTestPaper, testPaperQuestionUnits]);
};
