export const FeedbackScoreExplanationComponent = () => {
  return (
    <table
      style={{
        border: "1px solid",
        borderCollapse: "collapse",
        marginLeft: "12px",
        marginRight: "12px",
      }}
    >
      <tbody>
        <tr>
          <td
            style={{
              margin: 0,
              textAlign: "left",
              background: "yellow",
              border: "1px solid",
            }}
          >
            채점 기준
          </td>
        </tr>
      </tbody>
      <ScoreExplanationRow description={"5점 : 본인과 비슷하거나 그 이상"} />
      <ScoreExplanationRow description={"4점 : 당장 같이 일할 수 있는 정도"} />
      <ScoreExplanationRow
        description={"3점 : 조금 아쉬운 부분이 있지만 가르쳐 보고 싶은 정도"}
      />
      <ScoreExplanationRow
        description={"2점 : fail이기는 하나 그래도 괜찮은 부분이 있는 경우"}
      />
      <ScoreExplanationRow description={"1점 : fail"} />
    </table>
  );
};

const ScoreExplanationRow = ({ description }: any) => {
  return (
    <tbody>
      <tr>
        <td
          style={{
            border: "1px solid",
          }}
        >
          <p
            style={{
              margin: 0,
              textAlign: "left",
            }}
          >
            {description}
          </p>
        </td>
      </tr>
    </tbody>
  );
};
