import { Feedback } from "../../domain/feedback/feedback";
import { FeedbackScore } from "../../domain/feedback/feedbackScore";
import { fetchPostWithoutResponse } from "../common/api";

export const submitFeedbackService = async (
  testPaperFeedback: Feedback,
  chartImage: string,
  채점자이름: string,
  testPaperFeedbackScore: FeedbackScore
): Promise<void> => {
  await fetchPostWithoutResponse(
    "test-paper-feedback/submit",
    new FeedbackSubmitData(
      testPaperFeedback,
      chartImage,
      채점자이름,
      testPaperFeedbackScore
    )
  );
};

class FeedbackSubmitData {
  constructor(
    readonly testPaperFeedback: Feedback,
    readonly chartImage: string,
    readonly 채점자이름: string,
    readonly testPaperFeedbackScore: FeedbackScore
  ) {}
}
