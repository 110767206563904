import { useRecoilValue } from "recoil";
import {
  feedbackIdState,
  feedbackOverallCommentState,
  feedbackTestPaperTokenState,
  feedbackUnitsState,
} from "../../presentation/feedback/feedbackStates";
import { FeedbackUnit } from "../../domain/feedback/feedbackUnit";

export const useGetFeedbackPropertyStates: () => [
  FeedbackUnit[],
  string,
  string | null,
  string | null
] = () => {
  const feedbackOverallComment = useRecoilValue(feedbackOverallCommentState);
  const feedbackId = useRecoilValue(feedbackIdState);
  const feedbackUnits = useRecoilValue(feedbackUnitsState);
  const feedbackTestPaperToken = useRecoilValue(feedbackTestPaperTokenState);

  return [
    feedbackUnits,
    feedbackOverallComment,
    feedbackId,
    feedbackTestPaperToken,
  ];
};
