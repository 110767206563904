import { 테스트페이퍼_언어 } from "./테스트페이퍼_언어";

export class ApplicantInformation {
  constructor(
    readonly name: string,
    readonly sourceLanguage: 테스트페이퍼_언어,
    readonly targetLanguage: 테스트페이퍼_언어,
    readonly emailAddress: string,
    readonly contact: string
  ) {}
}
