import { useRecoilValue } from "recoil";
import { FeedbackScoreAssessment } from "../../domain/feedback/feedbackScoreAssessment";
import {
  feedback게임이해도State,
  feedback국문이해도State,
  feedback문법State,
  feedback작문능력State,
  feedback정확도State,
} from "../../presentation/feedback/feedbackStates";

export const useGetFeedbackScoreAssessments = (): [
  FeedbackScoreAssessment,
  FeedbackScoreAssessment,
  FeedbackScoreAssessment,
  FeedbackScoreAssessment,
  FeedbackScoreAssessment
] => {
  const 문법 = useRecoilValue(feedback문법State);
  const 국문이해도 = useRecoilValue(feedback국문이해도State);
  const 작문능력 = useRecoilValue(feedback작문능력State);
  const 게임이해도 = useRecoilValue(feedback게임이해도State);
  const 정확도 = useRecoilValue(feedback정확도State);

  return [문법, 국문이해도, 작문능력, 게임이해도, 정확도];
};
