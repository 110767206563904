import { TestPaperAutoSave } from "../../domain/auto-save/testPaperAutoSave";
import { fetchGet } from "../common/api";

export async function getAutoSavedTestPaperAnswerService(
  testPaperToken: string,
  autoSaveToken: string
): Promise<TestPaperAutoSave | null> {
  return await fetchGet(
    `test-paper-admin/auto-save/${testPaperToken}/${autoSaveToken}`
  );
}
