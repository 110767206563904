import React from "react";
import { FeedbackUnit } from "../../domain/feedback/feedbackUnit";
import { useRecoilState } from "recoil";
import { feedbackUnitsState } from "./feedbackStates";
import { TextEditorComponent } from "./text-editor/textEditorComponent";

export const FeedbackQuestionComponent = () => {
  const [feedbackUnits, setFeedbackUnits] = useRecoilState(feedbackUnitsState);

  const updateFeedbackUnit = (
    feedbackUnit: FeedbackUnit,
    targetIndex: number
  ) => {
    setFeedbackUnits(
      feedbackUnits.map((value, index) =>
        index === targetIndex ? feedbackUnit : value
      )
    );
  };

  return (
    <div style={{ margin: "10" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 4fr 4fr 4fr",
        }}
        className="borderDiv"
      >
        <div>Key</div>
        <div>SOURCE</div>
        <div>TARGET</div>
        <div>FEEDBACK</div>
      </div>
      {feedbackUnits
        .map((testPaperFeedbackUnit, index) => (
          <FeedbackQuestionRow
            key={index}
            testPaperFeedbackUnit={testPaperFeedbackUnit}
            index={index}
            updateFeedbackUnit={updateFeedbackUnit}
          />
        ))
        .slice(1)}
    </div>
  );
};

const FeedbackQuestionRow = ({
  testPaperFeedbackUnit,
  index,
  updateFeedbackUnit,
}: {
  testPaperFeedbackUnit: FeedbackUnit;
  index: number;
  updateFeedbackUnit: (feedbackUnit: FeedbackUnit, targetIndex: number) => void;
}) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 4fr 4fr 4fr",
      }}
      className="borderDiv"
    >
      <div key={testPaperFeedbackUnit.key + "Key"}>
        {testPaperFeedbackUnit.key}
      </div>
      <TextEditorComponent
        key={testPaperFeedbackUnit.key + "Question"}
        pretext={testPaperFeedbackUnit.question.replaceAll("\n", "<br/>")}
        setText={(value) =>
          updateFeedbackUnit(
            new FeedbackUnit(
              testPaperFeedbackUnit.key,
              value.replaceAll("<br/>", "\n"),
              testPaperFeedbackUnit.answer,
              testPaperFeedbackUnit.feedback
            ),
            index
          )
        }
      ></TextEditorComponent>
      <TextEditorComponent
        key={testPaperFeedbackUnit.key + "Answer"}
        pretext={testPaperFeedbackUnit.answer.replaceAll("\n", "<br/>")}
        setText={(value) =>
          updateFeedbackUnit(
            new FeedbackUnit(
              testPaperFeedbackUnit.key,
              testPaperFeedbackUnit.question,
              value.replaceAll("<br/>", "\n"),
              testPaperFeedbackUnit.feedback
            ),
            index
          )
        }
      ></TextEditorComponent>
      <TextEditorComponent
        key={testPaperFeedbackUnit.key + "Editor"}
        pretext={testPaperFeedbackUnit.feedback.replaceAll("\n", "<br/>")}
        setText={(value) =>
          updateFeedbackUnit(
            new FeedbackUnit(
              testPaperFeedbackUnit.key,
              testPaperFeedbackUnit.question,
              testPaperFeedbackUnit.answer,
              value.replaceAll("<br/>", "\n")
            ),
            index
          )
        }
      />
    </div>
  );
};
