import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { testPaperTokenState } from "../../presentation/ready/readyStates";
import { testPaperQuestionUnitsState } from "../../presentation/test/testStates";
import { submitTestPaperAnswerService } from "../../infrastructure/test/submitTestPaperAnswerService";

export const useSubmitTestPaper = () => {
  const navigate = useNavigate();
  const testPaperToken = useRecoilValue(testPaperTokenState);
  const testPaperQuestionUnits = useRecoilValue(testPaperQuestionUnitsState);

  return async () => {
    if (testPaperToken === null || testPaperToken.trim() === "") {
      throw Error(
        "세션이 끊겨서 테스트 페이퍼를 제출하지 못했습니다. 데이터를 백업하고 담당 PM에게 문의해주세요."
      );
    }

    if (testPaperQuestionUnits === null) {
      throw Error("문제를 선택해주세요.");
    }

    await submitTestPaperAnswerService(testPaperToken, testPaperQuestionUnits);
    navigate("/");
  };
};
