import * as React from "react";
import { useEffect, useMemo } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./textEditorComponent.css";

export interface TextEditorComponentProps {
  pretext: string;
  setText: (value: string) => void;
}

export const TextEditorComponent = ({
  pretext,
  setText,
}: TextEditorComponentProps) => {
  const ref = React.useRef<ReactQuill>(null);

  useEffect(
    () => ref.current?.editor?.root.setAttribute("spellcheck", "false"),
    []
  );

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
        ],
      },
    }),
    []
  );

  return (
    <ReactQuill
      ref={ref}
      modules={modules}
      style={{ minWidth: 0 }}
      value={pretext}
      onChange={(value) => {
        setText(value);
      }}
    />
  );
};
