import { getEveryAutoSavedTestPaperService } from "../../infrastructure/admin/getEveryAutoSavedTestPaperService";
import { getAutoSavedTestPaperAnswerService } from "../../infrastructure/admin/getAutoSavedTestPaperAnswerService";
import { useState } from "react";

export const useLoadAdminData: () => [
  {},
  (testPaperToken: string, autoSaveToken: string | null) => Promise<void>
] = () => {
  const [adminData, setAdminData] = useState({});

  return [
    adminData,
    async (testPaperToken: string, autoSaveToken: string | null) => {
      const testPaperAnswer =
        autoSaveToken === null || autoSaveToken.trim() === ""
          ? await getEveryAutoSavedTestPaperService(testPaperToken)
          : await getAutoSavedTestPaperAnswerService(
              testPaperToken,
              autoSaveToken
            );

      if (testPaperAnswer == null)
        throw Error("테스트 페이퍼 데이터가 없습니다.");
      else {
        setAdminData(testPaperAnswer);
      }
    },
  ];
};
