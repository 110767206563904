import { useNavigate } from "react-router-dom";

export const CautionPage = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div>
      <h1>주의사항</h1>
      <p>
        1. 스펠링, 띄어쓰기, 문장부호, 엑센트, 괄호, 태그(tag, 코딩 기호) 등의
        항목도 틀리게 되면 감점되므로 사소한 부분까지 꼼꼼하게 작성해주세요.
      </p>
      <p>
        2. 웹사전 검색 및 웹서칭 모두 가능합니다. 단, 'Google 번역', '네이버
        파파고' 또는 핸드폰 앱 등 자동 번역 기능을 사용하거나 타인과 상의하여
        작성하는 경우 실격 처리됩니다.
      </p>
      <p>
        3. 원어민의 경우 한국어 내용을 충분히 이해하는 것에 유의해주시고,
        내국인의 경우 자연스럽고 올바른 번역에 유의해주세요.
      </p>
      <p>
        4. 마지막 문항은 주어진 내용 자체를 번역하는 것이 아니고 내용을 읽고
        이해한 다음 그것에 대한 자신의 '생각'을 '테스트하는 언어'로
        작성해주세요.
      </p>
      <button
        onClick={() => {
          navigate("/test");
        }}
      >
        시작
      </button>
    </div>
  );
};
