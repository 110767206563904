import { useLoadAutoSave } from "../../application/restore/useLoadAutoSave";
import { useState } from "react";
import { ApiCallError } from "../../application/error/apiCallError";

export const RestorePage = () => {
  const [localTestPaperToken, setLocalTestPaperToken] = useState("");
  const [autoSaveToken, setAutoSaveToken] = useState("");
  const loadAutoSave = useLoadAutoSave();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <input
        placeholder="세션키"
        onChange={(e) => setLocalTestPaperToken(e.target.value)}
      />
      <input
        placeholder="자동저장키 (옵션)"
        onChange={(e) => setAutoSaveToken(e.target.value)}
      />
      <button
        onClick={async (event) => {
          const target = event.currentTarget;

          if (localTestPaperToken === null || localTestPaperToken.trim() === "")
            return;

          target.disabled = true;

          try {
            await loadAutoSave(localTestPaperToken, autoSaveToken);
          } catch (e: any) {
            if (e instanceof ApiCallError) alert("서버가 응답하지 않습니다.");
            else if (e instanceof Error) alert(e.message);

            target.disabled = false;
          }
        }}
      >
        불러오기
      </button>
    </div>
  );
};
