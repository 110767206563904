import React from "react";

import { useSubmitTestPaper } from "../../application/test/useSubmitTestPaper";
import { ApiCallError } from "../../application/error/apiCallError";

export function TestSubmitComponent(): JSX.Element {
  const submitTestPaper = useSubmitTestPaper();

  return (
    <button
      onClick={async (event) => {
        const target = event.currentTarget;
        target.disabled = true;

        try {
          await submitTestPaper();
          alert("제출이 완료되었습니다.");
        } catch (e: any) {
          if (e instanceof ApiCallError) alert("서버가 응답하지 않습니다.");
          if (e instanceof Error) alert(e.message);
          target.disabled = false;
        }
      }}
    >
      제출
    </button>
  );
}
