import { FeedbackScoreFormComponent } from "./feedbackScoreFormComponent";
import { PolarAngleAxis, PolarGrid, Radar, RadarChart } from "recharts";
import React from "react";
import { FeedbackScoreExplanationComponent } from "./feedbackScoreExplanationComponent";
import { useGetFeedbackScoreAssessments } from "../../../application/feedback/useGetFeedbackScoreAssessments";

export const FeedbackScoreComponent = ({
  areaRef,
}: {
  areaRef: React.MutableRefObject<any>;
}) => {
  const [문법, 국문이해도, 작문능력, 게임이해도, 정확도] =
    useGetFeedbackScoreAssessments();

  return (
    <div>
      <div style={{ display: "grid", gridTemplateColumns: "3fr 2fr" }}>
        <FeedbackScoreFormComponent />
        <FeedbackScoreExplanationComponent />
      </div>
      <div>
        <RadarChart
          style={{ textAlign: "center", width: "100%" }}
          outerRadius={240}
          width={900}
          height={600}
          data={[문법, 국문이해도, 작문능력, 게임이해도, 정확도]}
          ref={areaRef}
        >
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" tick={customTick} />
          <Radar
            dataKey="score"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.6}
          />
        </RadarChart>
      </div>
    </div>
  );
};

function customTick({ payload, x, y, textAnchor, stroke, radius }: any) {
  return (
    <g className="recharts-layer recharts-polar-angle-axis-tick">
      <text
        radius={radius}
        stroke={stroke}
        x={x}
        y={y}
        className="recharts-text recharts-polar-angle-axis-tick-value"
        textAnchor={textAnchor}
      >
        {payload.value.split("\n").map((va: string, index: number) => (
          <tspan key={index} x={x} y={y + 22 * index} dy="0em">
            {va}
          </tspan>
        ))}
      </text>
    </g>
  );
}
