import { ReadyLanguageSingleComponent } from "./readyLanguageSingleComponent";
import React from "react";
import { 테스트페이퍼_언어 } from "../../domain/테스트페이퍼_언어";
import { getEnumMembers } from "../utility";

import { useGetTestPaperLanguages } from "../../application/ready/useGetTestPaperLanguages";
import { useSetRecoilState } from "recoil";
import { sourceLanguageState, targetLanguageState } from "./readyStates";

export function ReadyLanguageComponent() {
  let languages = useGetTestPaperLanguages();
  const setSource = useSetRecoilState(sourceLanguageState);
  const setTarget = useSetRecoilState(targetLanguageState);

  return (
    <div>
      <p>번역 언어</p>
      <ReadyLanguageSingleComponent
        optionPlaceholder={"Source Language"}
        languages={languages}
        setLanguage={setSource}
      />
      <ReadyLanguageSingleComponent
        optionPlaceholder={"Target Language"}
        languages={getEnumMembers(테스트페이퍼_언어)}
        setLanguage={setTarget}
      />
    </div>
  );
}
