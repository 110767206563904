import { TestPaperQuestionUnit } from "../../domain/testPaperQuestionUnit";
import { fetchPostWithoutResponse } from "../common/api";

export async function saveTestPaperAutoSaveService(
  testPaperToken: string,
  testPaperQuestionUnits: TestPaperQuestionUnit[]
): Promise<void> {
  await fetchPostWithoutResponse("test-paper/auto-save", {
    testPaperToken: testPaperToken,
    testPaperQuestion: testPaperQuestionUnits,
  });
}
