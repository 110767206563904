import { ApplicantInformation } from "../../domain/applicantInformation";
import { startTestPaperService } from "../../infrastructure/test/startTestPaperService";
import { useSetRecoilState } from "recoil";
import {
  applicantInformationState,
  testPaperTokenState,
} from "../../presentation/ready/readyStates";
import { useNavigate } from "react-router-dom";
import { useGetApplicantInformationPropertyStates } from "./useGetApplicantInformationPropertyStates";
import { getTestPaperMetadataService } from "../../infrastructure/question/getTestPaperMetadataService";
import { testPaperQuestionUnitsState } from "../../presentation/test/testStates";
import { getTestPaperQuestionService } from "../../infrastructure/question/getTestPaperQuestionService";

export const useStartTestPaper: () => () => Promise<void> = () => {
  const [name, source, target, email, contact] =
    useGetApplicantInformationPropertyStates();
  const setApplicantInformation = useSetRecoilState(applicantInformationState);
  const setTestPaperToken = useSetRecoilState(testPaperTokenState);
  const setTestPaperQuestion = useSetRecoilState(testPaperQuestionUnitsState);
  const navigate = useNavigate();

  return async () => {
    if (name.trim() === "") {
      throw Error("이름을 적어주세요.");
    }

    if (source === null) {
      throw Error("소스 언어를 선택해주세요.");
    }

    if (target === null) {
      throw Error("타겟 언어를 선택해주세요.");
    }

    if (email.trim() === "") {
      throw Error("이메일 주소를 적어주세요.");
    }

    if (contact.trim() === "") {
      throw Error("연락처를 적어주세요.");
    }

    const applicantInformation = new ApplicantInformation(
      name,
      source,
      target,
      email,
      contact
    );

    setApplicantInformation(applicantInformation);
    const token = await startTestPaperService(applicantInformation);
    setTestPaperToken(token);

    const questionName = await getTestPaperMetadataService(source);
    const testPaperQuestionUnits = await getTestPaperQuestionService(
      questionName[0]
    );
    setTestPaperQuestion(testPaperQuestionUnits);

    navigate("/caution");
  };
};
