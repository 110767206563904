import React from "react";
import { useStartTestPaper } from "../../application/ready/useStartTestPaper";
import { ApiCallError } from "../../application/error/apiCallError";

export function ReadyStartComponent(): JSX.Element {
  const startTestPaper = useStartTestPaper();

  return (
    <button
      style={{ marginTop: 24 }}
      onClick={async (event) => {
        const target = event.currentTarget;
        target.disabled = true;

        try {
          await startTestPaper();
        } catch (e: any) {
          if (e instanceof ApiCallError) alert("서버가 응답하지 않습니다.");
          else if (e instanceof Error) alert(e.message);
          target.disabled = false;
        }
      }}
    >
      다음
    </button>
  );
}
