import { TestPaper } from "../../domain/testPaper";
import { TestPaperAutoSave } from "../../domain/auto-save/testPaperAutoSave";
import { fetchGet } from "../common/api";

export async function getEveryAutoSavedTestPaperService(
  testPaperToken: string
): Promise<{
  testPaperSessionModel: TestPaper;
  testPaperAutoSaveModels: TestPaperAutoSave[];
} | null> {
  return fetchGet(`test-paper-admin/test-paper/${testPaperToken}`);
}
