export class FeedbackScoreAssessment {
  subject: string;
  score: number;
  realScore: number;

  constructor(subject: string, score: number) {
    this.subject = subject;
    this.realScore = score;
    this.score = score * 4;
  }
}
