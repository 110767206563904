import { ApiCallError } from "../../application/error/apiCallError";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export async function fetchGet(url: string): Promise<any> {
  let response;
  try {
    response = await fetch(apiServerUrl + url);
  } catch {
    throw new ApiCallError();
  }
  if (response.status !== 200) throw Error(await response.text());
  const body = await response.text();
  if (body.length === 0) return null;
  return JSON.parse(body);
}

export async function fetchPostWithoutResponse<T>(
  url: string,
  data: T
): Promise<void> {
  let response;
  try {
    response = await fetch(apiServerUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  } catch {
    throw new ApiCallError();
  }

  if (!response.ok) throw Error(await response.text());
}

export async function fetchPostWithResponse<TBody, TResponse>(
  url: string,
  data: TBody
): Promise<TResponse> {
  let response;
  try {
    response = await fetch(apiServerUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  } catch {
    throw new ApiCallError();
  }

  if (!response.ok) throw Error(await response.text());
  return await response.json();
}
